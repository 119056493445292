import React from 'react'

export function Tabs() {
  return (
    <div className="mb-4">
      <div className="sm:hidden">
        <select className="block w-full form-select">
          <option>My Account</option>
          <option>Company</option>
          <option selected>Team Members</option>
          <option>Billing</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <h4 className="block mb-1 text-sm font-medium leading-5 text-gray-700">
          Filter by template:
        </h4>
        <nav className="flex">
          <a
            href="/"
            className="px-3 py-2 text-sm font-medium leading-5 text-teal-700 bg-teal-100 rounded-md focus:outline-none focus:text-teal-800 focus:bg-teal-200"
          >
            All Types
          </a>

          <a
            href="/"
            className="px-3 py-2 ml-4 text-sm font-medium leading-5 text-gray-500 rounded-md hover:text-gray-700 focus:outline-none focus:text-teal-600 focus:bg-teal-50"
          >
            At-Home Experiences
          </a>
          <a
            href="/"
            className="px-3 py-2 ml-4 text-sm font-medium leading-5 text-gray-500 rounded-md hover:text-gray-700 focus:outline-none focus:text-teal-600 focus:bg-teal-50"
          >
            At-Home Kits
          </a>

          <a
            href="/"
            className="px-3 py-2 ml-4 text-sm font-medium leading-5 text-gray-500 rounded-md hover:text-gray-700 focus:outline-none focus:text-teal-600 focus:bg-teal-50"
          >
            Gift Cards
          </a>
        </nav>
      </div>
    </div>
  )
}
