import { Tabs } from './../components/sections/tabs'
import PageHeader from '../components/sections/page-header'
import { Button } from '../components/buttons'
import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

// TODO: Move to to component lib?
const ServicePanel = (i) => (
  <div
    key={i}
    className="overflow-hidden bg-white border border-gray-200 shadow sm:rounded-md"
  >
    <div className="px-4 py-5 sm:px-6">
      <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-no-wrap">
        <div className="flex items-center mt-2 ml-4">
          <h3 className="text-lg font-medium leading-6 text-gray-500">
            At-Home Experience
          </h3>
        </div>
        <div className="flex-shrink-0 mt-2 ml-4">
          <div className="inline-flex mt-5 lg:mt-0 lg:ml-4 space-x-4">
            <Link
              to="/athome_setup"
              className="inline-flex items-center text-gray-600 underline align-baseline"
            >
              <svg
                fill="currentColor"
                viewBox="0 0 20 20"
                className="w-5 h-5 mr-1 text-gray-500"
              >
                <path
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clipRule="evenodd"
                  fillRule="evenodd"
                ></path>
              </svg>
              Settings
            </Link>
            <Link className="inline-flex items-center text-teal-600 underline">
              <svg
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
                className="w-5 h-5 mr-1 text-teal-500"
              >
                <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              Create new event
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-gray-50 divide-gray-200 divide-y">
      {[1, 2, 3, 4, 5].map((i) => {
        return (
          <a
            key={i}
            href="/"
            className="block transition duration-150 ease-in-out sm:px-6 hover:bg-gray-100 focus:outline-none focus:bg-gray-200"
          >
            <div className="flex items-center py-4">
              <div className="flex items-center flex-1 min-w-0">
                <div className="flex-1 min-w-0 pr-4 md:grid md:grid-cols-2 md:gap-6">
                  <div className="text-sm font-medium leading-5 text-gray-500 underline truncate">
                    ONLINE CLASS: Flower Power
                  </div>
                  <div className="flex items-center text-sm leading-5 text-gray-500">
                    <svg
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M17 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h2V3a1 1 0 1 1 2 0v1h6V3a1 1 0 0 1 2 0v1zm-2 2H9v1a1 1 0 1 1-2 0V6H5v4h14V6h-2v1a1 1 0 0 1-2 0V6zm4 6H5v8h14v-8z" />
                    </svg>
                    <span className="truncate">Sun, Apr 19, 2020 1:30 PM</span>
                  </div>
                </div>
              </div>
              <div>
                <svg
                  className="w-5 h-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </a>
        )
      })}
    </div>

    <Link
      to="/listings"
      className="block px-6 py-3 text-sm font-medium leading-5 text-center text-teal-600 transition duration-300 ease-in-out hover:bg-teal-100 focus:outline-none focus:bg-teal-200"
    >
      Show all events &rarr;
    </Link>
  </div>
)

function IndexPage() {
  return (
    <Layout restricted>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <PageHeader
        title="Services"
        items={[
          {
            text: '15 events',
            icon: (
              <path
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clipRule="evenodd"
                fillRule="evenodd"
              ></path>
            ),
          },
          {
            text: '4 services',
            icon: (
              <path
                fillRule="evenodd"
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
              />
            ),
          },
        ]}
      >
        <Button
          style="secondary"
          size="sm"
          icon={
            <path
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
              clipRule="evenodd"
              fillRule="evenodd"
            ></path>
          }
        >
          Add new service
        </Button>
      </PageHeader>

      <Tabs />

      <div className="">
        <section className="grid gap-6 md:grid-cols-2">
          {/* TODO: link products */}
          {[1, 2, 3].map((i) => {
            return ServicePanel(i)
          })}
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage
